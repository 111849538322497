<template>
    <div class="overview">

   <!-- Header start -->
    <header class="header">
        <div class="header__container flex-space">
            <a href="/" >
                <h1>vim.is</h1>
            </a>
          
          
        </div>
    </header>
    <!-- Header end -->

	<div class="basic-1 cards-1">
	<div class="container">
		<div class="row">

        <div class="col-lg-12 ">
           <h2>Overview</h2>
<br>
<div class="testimonial-item">
                    <div class="testimonial-item__avatar">
                        <img src="../assets/hero/logo-footer.svg" alt="">
                    </div>
                    <span class="testimonial-item__name">Exercises
                    </span>
                    <div class="testimonial-item__text">
                       
     <ul>
            <li><a href="/exercise/0">Move cursor basic</a><br></li>
            <li><a href="/exercise/1">Move cursor around</a><br></li>
            <li><a href="/exercise/2">Move cursor between words</a><br></li>
            <li><a href="/exercise/3">Delete two words at once</a><br></li>
            <li><a href="/exercise/4">Cursor end of line</a><br></li>
            <li><a href="/exercise/5">Append</a><br></li>
            <li><a href="/exercise/6">Ce</a><br></li>
            <li><a href="/exercise/7">Copy</a><br></li>
            <li><a href="/exercise/8">Copy line</a><br></li>
            <li><a href="/exercise/9">Copy paste</a><br></li>
            <li><a href="/exercise/10">Cut</a><br></li>
            <li><a href="/exercise/11">Delete x words</a><br></li>
            <li><a href="/exercise/12">Delete bulk</a><br></li>
            <li><a href="/exercise/13">Delete line</a><br></li>
            <li><a href="/exercise/14">Delete until end of line</a><br></li>
            <li><a href="/exercise/15">Move to end of file</a><br></li>
            <li><a href="/exercise/16">Insert line above</a><br></li>
            <li><a href="/exercise/17">Insert line below</a><br></li>
            <li><a href="/exercise/18">Insert</a><br></li>
            <li><a href="/exercise/19">Insert at start</a><br></li>
            <li><a href="/exercise/20">Jump char</a><br></li>
            <li><a href="/exercise/21">Jump</a><br></li>
            <li><a href="/exercise/22">Jump to line</a><br></li>
            <li><a href="/exercise/23">Jump words</a><br></li>
            <li><a href="/exercise/24">Move</a><br></li>
            <li><a href="/exercise/25">Repeat</a><br></li>
            <li><a href="/exercise/26">Replace char</a><br></li>
            <li><a href="/exercise/27">Replace</a><br></li>
            <li><a href="/exercise/28">Replace mode</a><br></li>
            <li><a href="/exercise/29">Search replace</a><br></li>
            <li><a href="/exercise/30">swap</a><br></li>
            <li><a href="/exercise/31">yank to end</a><br></li>
            <li><a href="/exercise/32">move and select word</a><br></li>
            <li><a href="/exercise/33">record and replay</a><br></li>
            <li><a href="/exercise/34">Go to the next occurrence of word</a><br></li>
            <li><a href="/exercise/35">Select word, replace by character</a><br></li>
            <li><a href="/exercise/36">Increase/decrease number</a><br></li>
            <li><a href="/exercise/37">Make line upper/lower case</a><br></li>
            <li><a href="/exercise/38">Make text upper/lower case</a><br></li>
            <li><a href="/exercise/39">Jump to matching bracket (programming)</a><br></li>
            <li><a href="/exercise/40">Indent lines (programming)</a><br></li>
            <li><a href="/exercise/41">Keyboard macros</a><br></li>
            <li><a href="/exercise/42">Sort lines</a><br></li>            
</ul>

                    </div>
                </div>

       
      </div>
      </div>
  </div>

  </div>
  </div>
</template>

<script>
import router from '../router'

export default {
    components: {
    },
    name: 'HelloWorld',
    props: {
        msg: String
    },
    computed: {
        formattedElapsedTime() {
            const date = new Date(null);
            //date.setSeconds(this.elapsedTime / 1000);
            date.setMilliseconds(this.elapsedTime);
            const utc = date.toISOString();
            return utc.substr(utc.indexOf(".") - 2, 5) + 's';
        },
        id() {
            return this.$route.params.id;
        },
    },
    created: function() {

        var key = this.$cookie.get('key');
        console.log(key);

        if (key === null) 
            router.replace("/login");

        this.getData();

    },
    methods: {
        getId: function() {
            return this.$route.params.id;
        },
        start: function() {
            this.startTimer();
        },
        editorInit: function(editor) {
            require('brace/mode/html')
            require('brace/mode/python')
            require('brace/mode/less')
            require('brace/theme/chrome')
            require('brace/theme/dracula')
            require('brace/theme/monokai')
            editor.setOption('fontSize', '16pt')
            editor.setOption('vScrollBarAlwaysVisible', true)
            editor.setOption('theme', 'ace/theme/textmate')
            require('brace/keybinding/vim')
            editor.setKeyboardHandler('ace/keyboard/vim')

            //this.answer = this.placeholders[this.index];
        },
        editorRender: function(output) {
            require('brace/mode/html')
            require('brace/mode/python')
            require('brace/mode/less')
            require('brace/theme/chrome')
            require('brace/theme/chaos')
            require('brace/theme/monokai')
            output.setOption('fontSize', '16pt')
            output.setOption('vScrollBarAlwaysVisible', true)
            output.setOption('theme', 'ace/theme/textmate')
        },
        onChange: function() {
            this.correct();
        },
        correct: function() {
            //console.log(' answer   = ', this.answer, this.answer.length, typeof this.answer);
            //console.log(' solution = ', this.data[this.index].solution, this.index, this.data[this.index].solution.length, typeof this.data[this.index].solution);
            if (this.data[this.index].solution == this.answer) {
                //console.log(this.index);
                //console.log(this.data.length);
                
                var myid = this.getId();
                
                if (this.index >= this.data.length - 1) {
                  console.log('next exercise');
                  this.index = 0;
                  this.score = 0;
                  var id = Number(myid) + 1;
                  this.id = id;
                  this.getData();
                  router.replace("/exercise/" + (id) );
                }
                this.oldIndex = 0;
                //console.log('correct');
                this.answer = '';
                this.score = this.score + 1;
                this.index = this.index + 1;
                //console.log('index is ', this.index);
                this.totalTime += this.elapsedTime;
                this.calculateAverageTime();
                this.resetTimer();
                this.startTimer();
                
                //this.answer = this.data[this.index].placeholder;

                setTimeout(() => {
                    this.answer = this.data[this.index].placeholder;
                }, 10)

            }
        },
        reset: function() {
            console.log('reset');
            this.index = 0 ;
            this.score = 0;
            this.answer = this.data[this.index].placeholder;
            this.totalTime = 1;
            this.resetTimer();
            this.calculateAverageTime();
        },
        startTimer() {
            this.timer = setInterval(() => {
                this.elapsedTime += 101;
            }, 101);
        },
        stopTimer() {
            clearInterval(this.timer);
        },
        resetTimer() {
            this.elapsedTime = 1;
        },
        calculateAverageTime() {
            const date = new Date(null);
            //date.setSeconds(this.elapsedTime / 1001);
            date.setMilliseconds(this.totalTime / this.score);
            const utc = date.toISOString();
            this.averageTime = utc.substr(utc.indexOf(".") - 3, 5) + 's';
        },
        getData() {
            fetch(this.api[this.id])
                .then(response => response.json())
                .then(data => (this.data = data))
                .then(response => {
                    this.answer = this.data[this.index].placeholder;
                    console.log('fetched data', response);
                });

        },
    },
    data: function() {
        return {
            elapsedTime: 1,
            totalTime: 1,
            averageTime: 1,
            timer: undefined,
            answer: "",
            score: 0,
            index: 0,
            data: [{},{}],
            title: '',
            exercise: [],
            api: [
                '../vim-cursor.json',
                '../vim-cursor2.json',
                '../vim-cursor3.json',
                '../vim-cursor4.json',
                '../vim-cursor-end-of-line.json',
                '../vim-append.json',
                '../vim-ce.json',
                '../vim-copy.json',
                '../vim-copy-line.json',
                '../vim-copypaste.json',
                '../vim-cut.json',
                '../vim-d2w.json',
                '../vim-delete-bulk.json',
                '../vim-delete-line.json',
                '../vim-delete-until-end-of-line.json',
                '../vim-g.json',
                '../vim-insert-above.json',
                '../vim-insert-below.json',
                '../vim-insert.json',
                '../vim-insert-start.json',
                '../vim-jump-char.json',
                '../vim-jump.json',
                '../vim-jump-line.json',
                '../vim-jump-words.json',
                '../vim-move.json',
                '../vim-repeat.json',
                '../vim-replace-char.json',
                '../vim-replace.json',
                '../vim-replace-mode.json',
                '../vim-search-replace.json',
                '../vim-swap.json',
                '../vim-yank-to-end.json'
            ]
        }
    }
}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@import '../assets/hero/css2.css';
@import '../assets/hero/normalize.css';
@import '../assets/hero/style.css';
@import '../assets/hero/responsive.css';

.btn.btn__default {
    color: black;
}

.above-heading {
    font-size: 12px;
    font-style: bold;
}

.hero-cta {
    color: white;
}

h2 {
    font-size: 4.2rem;
    line-height: 1.1;
    margin-bottom: 25px;
}
.above-heading {
    color: #5f4dee;
    font: 0.70.75rem/0.075rem "Open Sans", sans-serif;
    text-align: center;
}

h4 {
    margin: 41px 0 0;
}

ul {
    text-align:left;
}


#parent {
    width: 101%;
    height: 226px;
    display: block;
    position: relative;
}

#editor {
    position: absolute;
    /* Added */
    top: 1;
    right: 1;
    bottom: 1;
    left: 1;
    margin: 1 auto;
    border-radius: 9px;
}

.btn-solid-reg {
    cursor: pointer;
}

.score {
    font: 701 1rem/1.625rem "Open Sans", sans-serif;
    letter-spacing: 1.1px;
}

.divider {
    height: 2px;
    margin-top: 2.75rem;
    margin-bottom: 3rem;
    border: none;
    background-color: #ccd4df;
}

.box {
    display: block;
    max-width: 20rem;
    margin-right: auto;
    margin-bottom: 4rem;
    margin-left: auto;
    border: 2px solid #ccd3df;
    border-radius: 1.375rem;
    ;
    display: inline-block;
    margin: 11px;
    padding: 16px;
}

.testimonial-item__avatar {
    height: 6rem;
    width: 6rem;
    position: absolute;
    top: -6.4rem;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    border-radius: 50%;
    border: 0px;
}

:link { color: #0000EE; }
:visited { color: #551A8B; }

</style>
